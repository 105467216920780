import React, { useState } from 'react';
import './styleComponentUtils.css';
import HighlightOffTwoToneIcon from '@mui/icons-material/HighlightOffTwoTone';
import { deleteFamilyMemberProfile } from '../../../actions/matrimonialAction';
import { toast } from 'react-toastify';
import LoadingSpinner from '../../loader';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { CircularProgress } from '@material-ui/core';

function EditFamilyMember({ memberProfile, handleEditClick, postDeleteFamilyMember }) {
  const [loading, setLoading] = useState(false);
  const name = memberProfile?.metadata?.profile_data?.name || memberProfile?.independent_profile_data?.name || '';
  const relation = memberProfile?.matrimonial_id_data[0]?.relation || '';
  const occupation = memberProfile?.occupation || '';
  const qualification = memberProfile?.qualification || '';
  const picUrl = memberProfile?.metadata?.profile_data?.pic_url || '';

  const handleDeleteClick = async () => {
    const confirmDelete = window.confirm('Are you sure you want to delete this family member?');
    if (!confirmDelete) return; // If user cancels, do nothing
    try {
      setLoading(true);
      const response = await deleteFamilyMemberProfile(memberProfile?._id);
      setLoading(false);
      if (response?.status) {
        toast.success('Family Member Deleted Successfully');
        postDeleteFamilyMember();
      } else {
        toast.error(`Failed to delete family member, ${response.error_message}`);
      }
    } catch (error) {
      console.error('Error deleting family member:', error);
      toast.error(`An error occurred while deleting family member, ${error}`);
      setLoading(false);
    }
  };

  return (
    <>
      {/* {loading && <LoadingSpinner message="Deleting Family Member" />} */}
      <div className="frame-edit-tile">
        <div className="image-close-combo-edit-tile">
          <div className="div-edit-tile">
            {picUrl ? (
              <img className="ellipse-edit-tile" alt="Ellipse" src={picUrl} />
            ) : (
              <AccountCircleIcon className="post_avatar" />
            )}
            <div className="close-instance-edit-tile" color="white" onClick={handleDeleteClick}>
              <div className="close-screen-edit-tile">
                <div className="overlap-group-edit-tile">
                  {loading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    <HighlightOffTwoToneIcon sx={{ color: 'white', backgroundColor: 'black', borderRadius: '50%' }} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="div-2-edit-tile">
          <div className="name">{name}</div>
          <div className="text-wrapper-edit-tile">{relation}</div>
          <div className="text-wrapper-edit-tile">{occupation}</div>
          <div className="text-wrapper-edit-tile">{qualification}</div>
          <div className="text-wrapper-2-edit-tile" onClick={() => handleEditClick(memberProfile)}>
            Edit
          </div>
        </div>
      </div>
    </>
  );
}

export default EditFamilyMember;
